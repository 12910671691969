.greeting {
  color: white;
  font-family: "Lobster", cursive;
  font-size: 50px;
}

.dwnbtn1 {
  margin-top: 150px;
}

.dwnbtn2 {
  margin-top: -250px;
}

.btnItem {
  margin-right: -125px;
  margin-top: -100px;
}

.wlcmVeera {
  color: white;
  font-size: 25px;
  //   font-family: 'Lobster', cursive;
  // font-family: 'Playfair Display', serif;
}

.logoSide {
  width: 75px;
  height: 75px;
  margin-top: -20px;
}

.downbtn {
  margin-top: 25px;
}

.hr.new3 {
  border-top: 10px solid red;
}

.downbtnedit {
  margin-top: 25px;
}

.agentImg {
  max-width: 200px;
  max-height: 200px;
  margin-top: -20px;
}

.shopImg {
  max-width: 200px;
  max-height: 200px;
  // margin-top: -120px;
  margin-left: px;
}

.buttonLine {
  margin-top: 25px;
}

.agentImageBtn {
  margin-left: 10px;
  margin-top: 5px;
}

.agentImageBtn2 {
  margin-left: 10px;
  margin-top: px;
}

.empFont {
  margin-left: 10px;
}

.shopdownbtn {
  margin-top: 30px;
}

.shopbuttonLine {
  margin-top: 25px;
  margin-left: 10px;
}

.dwnfile {
  margin-top: 5px;
}

.dwnfile02 {
  margin-top: 30px;
}

.dwnfileEdit {
  margin-top: 30px;
}

.btnform {
  background-color: #eb4d4b;
  border: none;
  color: white;
  // padding: 12px 16px;
  font-size: 12px;
  cursor: pointer;
  // margin-top: 5px;
  width: 38px;
  height: 38px;
}

/* Darker background on mouse-over */
// .btnform:hover {
//   background-color: RoyalBlue;
// }

.dwnbField {
  margin-top: 20px;
}

.fontColor {
  color: black;
}

.alignFont {
  margin-left: 15px;
}

.rightField {
  margin-left: 25px;
}

.downbtnAccess {
  margin-top: 30px;
}

.double {
  border-style: solid;
  width: 200px;
  height: 28px;
  display: flex;
  align-items: center;
}

.theme {
  // margin-bottom: center;
  // margin-left: auto;
  // margin: center;

  border-radius: 20px;
  background-color: white;
  width: 169%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
  margin-left: -70px;
  // right: 90px;

  // margin-bottom: -110px;
}

.top-bar {
  text-decoration: none;
}

.icon-style {
  color: #6993ff;
  size: 10rem;
}

.icon-style:hover {
  color: white;
}

.topic {
  // margin-left: 180px;
  font-size: 30px;
  color: #1f0a0a;
  // font-family: "serif";
  // border: 2px solid red;
  // border-radius: 12px;
  // background-color: rgb(29, 28, 28);
  // width: 350px;
  // height: 65px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;
  // align-items: center;
  // margin-bottom: -30px;
}

.version {
  // margin-bottom: 20px;
  font-size: 18px;
  // font-family: "Scheherazade New", serif;
  // border-radius: 8px;
  // width:250px;
  // color:rgb(0, 0, 0);
  // margin-top: auto;
  // margin-right: -40px;
  // border-radius: 12px;
  // background-color: white;
  // float: right;
  // height: 45px;
  // display: flex;
  // // flex-direction: column;
  // justify-content: space-around;
  // align-items: center;
}

@media (max-width: 1199px) {
  .theme {
    border-radius: 20px;
    background-color: white;
    width: 915px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
    position: relative;
    left: -10px;
    z-index: -10;
    left: 5px;

    // margin-bottom: -110px;
  }
}

@media (min-width: 1383px) {
  .theme {
    border-radius: 20px;
    background-color: white;
    width: 165%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
    position: relative;
    // left: 5px;
    // right: -100px;
    z-index: -10;
    // left: 90px;

    // margin-bottom: -110px;
  }
}

@media (min-width: 1398px) {
  .theme {
    border-radius: 20px;
    background-color: white;
    width: 199%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
    position: relative;
    // left: -5px;
    z-index: -10;
    // left: 90px;

    // margin-bottom: -110px;
  }
}

// @media (max-width: 1183px) {
//   .theme {
//     border-radius: 20px;
//     background-color: white;
//     width: 950px;
//     height: 100px;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-around;
//     align-items: center;
//     margin-top: 40px;
//     position: relative;
//     right: 90px !important;

//     // margin-bottom: -110px;
//   }
// }

.imagelogo {
  margin-bottom: -15px;
  // background-color: white;
  // border-radius: 8px;
  width: 120px;
  height: 55px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;
  // align-items: center;
  // border: 4px solid black;
}
